exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archivo-index-js": () => import("./../../../src/pages/archivo/index.js" /* webpackChunkName: "component---src-pages-archivo-index-js" */),
  "component---src-pages-archivo-sanity-artwork-slug-current-jsx": () => import("./../../../src/pages/archivo/{sanityArtwork.slug__current}.jsx" /* webpackChunkName: "component---src-pages-archivo-sanity-artwork-slug-current-jsx" */),
  "component---src-pages-eventos-index-js": () => import("./../../../src/pages/eventos/index.js" /* webpackChunkName: "component---src-pages-eventos-index-js" */),
  "component---src-pages-eventos-sanity-flyer-slug-current-jsx": () => import("./../../../src/pages/eventos/{sanityFlyer.slug__current}.jsx" /* webpackChunkName: "component---src-pages-eventos-sanity-flyer-slug-current-jsx" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sanity-about-slug-current-js": () => import("./../../../src/pages/{sanityAbout.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-about-slug-current-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

